// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
// @import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

h1, h2, h3, h4, h5 {
    font-family: $font-family-1;
}

p {
    font-family: $font-family-2;
    font-size: $font-size-28;
    font-weight: normal;
    color: #d6d6d6;
    margin: 0;
    line-height: 1.5;
}

/** Replace the width of the container **/
.container {
    width: 1550px;
    @media screen and (max-width: 1550px) {
        width: 100%;
        padding: 0 25px;
    }
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
@import 'modules/module-header';
@import 'modules/module-soumission';




/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#section-background {
    padding-top: 10px;
    background: #000;
}
#section-background.last {
    padding-top: 0;
    padding-bottom: 10px;
    background: #000;
}

#main-section {
    background: #000;
    padding: 75px 0;
    .section-text-img {
        display: flex;
        .left-side {
            width: 50%;
            margin-right: 5%;
            h3 {
                font-weight: normal;
                font-size: $font-size-48;
                color: #d6d6d6;
                padding-bottom: 15px;
            }
        }
        .right-side {
            width: 50%;
            display: flex;
            .bloc {
                background: #fff;
                width: 350px;
                height: 315px;
                margin-right: 25px;
                display: flex;
                flex-direction: column;
                padding-left: 8%;
                justify-content: center;
                align-items: flex-start;
                h4 {
                    font-weight: normal;
                    font-size: $font-size-36;
                    color: $color-black;
                }
                h3 {
                    font-weight: normal;
                    font-size: $font-size-60;
                    color: $color-black;
                }
            }
            .image {
                .img-responsive {
                    width: 350px;
                    height: 315px;
                }
            }
        }
    }
    .section-text {
        padding: 25px  0 50px  0;
    }
    .section-burst {
        display: flex;
        flex-wrap: wrap;
        .burst.section-logo {
            display: flex;
            align-items: center;
            width: 49%;
            min-height: 120px;
            margin: 5px;
        }
        a.burst {
            width: 49%;
            background: #aaa8a9;
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 120px;
            margin: 5px;
            .text {
                margin-right: 35px;
                h4 {
                    font-weight: normal;
                    font-size: $font-size-36;
                    color: $color-black;
                }
                h3 {
                    font-weight: normal;
                    font-size: $font-size-60;
                    color: $color-black;
                }
            }
            .icon {
                .img-responsive.hover {
                    display: none;
                }
            }
            &:hover {
                background: #fff;
                .text {
                    h4, h3 {
                        color: #aaa8a9;
                    }
                }
                .icon {
                    .img-responsive.hover {
                        display: block;
                    }
                    .img-responsive.defaut {
                        display: none;
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* SERVICES
*******************************************************************************/

#section-title {
    background: #000;
    padding: 10px 0 0 0;
    h2 {
        font-weight: normal;
        font-size: $font-size-60;
        color: $color-black;
        text-shadow: 0 0 10px $color-white;
    }   
}

#section-intro {
    background: #000;
    padding: 75px 0;
    h3 {
        font-weight: normal;
        font-size: $font-size-48;
        color: #d6d6d6;
        padding-bottom: 15px;
    }
}

#section-text-burst {
    background: #000;
    padding-bottom: 50px;
    .container.flex {
        .left-side {
            width: 50%;
            margin-right: 5%;
            h3 {
                font-weight: normal;
                font-size: $font-size-48;
                color: #d6d6d6;
                padding-bottom: 15px;
            }
            .bloc {
                display: flex;
                .icon {
                    padding: 8px 30px;
                }
                .text {
                    p {
                        font-family: $font-family-2;
                        font-size: $font-size-30;
                        font-weight: normal;
                        color: #aaa8a9;
                        margin: 0;
                        line-height: 1.3;
                        strong {
                            color: #fff;
                            font-weight: bold;
                        }
                    }
                }
            }
        }
        .right-side {
            width: 50%;
            a.burst {
                background: #aaa8a9;
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: 120px;
                .text {
                    margin-right: 35px;
                    h4 {
                        font-weight: normal;
                        font-size: $font-size-36;
                        color: $color-black;
                    }
                    h3 {
                        font-weight: normal;
                        font-size: $font-size-60;
                        color: $color-black;
                    }
                }
                .icon {
                    .img-responsive.hover {
                        display: none;
                    }
                }
                &:hover {
                    background: #fff;
                    .text {
                        h4, h3 {
                            color: #aaa8a9;
                        }
                    }
                    .icon {
                        .img-responsive.hover {
                            display: block;
                        }
                        .img-responsive.defaut {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

#section-list {
    background: #000;
    padding-bottom: 100px;
    .container.flex {
        .left-side {
            width: 50%;
            margin-right: 5%;
        }
        .right-side {
            width: 50%;
        }
        .bloc {
            display: flex;
            .icon {
                padding: 8px 30px;
            }
            .text {
                p {
                    font-family: $font-family-2;
                    font-size: $font-size-30;
                    font-weight: normal;
                    color: #aaa8a9;
                    margin: 0;
                    line-height: 1.3;
                    strong {
                        color: #fff;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* À PROPOS
*******************************************************************************/

#section-text-burst.propos {
    padding: 75px 0 25px 0;
    .left-side {
        a.burst {
            background: #aaa8a9;
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 120px;
            .text {
                margin-right: 35px;
                h4 {
                    font-weight: normal;
                    font-size: $font-size-36;
                    color: $color-black;
                }
                h3 {
                    font-weight: normal;
                    font-size: $font-size-60;
                    color: $color-black;
                    padding-bottom: 0;
                }
            }
            .icon {
                .img-responsive.hover {
                    display: none;
                }
            }
            &:hover {
                background: #fff;
                .text {
                    h4, h3 {
                        color: #aaa8a9;
                    }
                }
                .icon {
                    .img-responsive.hover {
                        display: block;
                    }
                    .img-responsive.defaut {
                        display: none;
                    }
                }
            }
        }
    }
    .right-side {
        display: flex;
    }
}

#section-text {
    background: #000;
    padding-bottom: 100px;
}

#section-burst {
    background: #000;
    padding-bottom: 50px;
    a.burst {
        width: 49%;
        background: #aaa8a9;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 120px;
        margin: 5px;
        .text {
            margin-right: 35px;
            h4 {
                font-weight: normal;
                font-size: $font-size-36;
                color: $color-black;
            }
            h3 {
                font-weight: normal;
                font-size: $font-size-60;
                color: $color-black;
            }
        }
        .icon {
            .img-responsive.hover {
                display: none;
            }
        }
        &:hover {
            background: #fff;
            .text {
                h4, h3 {
                    color: #aaa8a9;
                }
            }
            .icon {
                .img-responsive.hover {
                    display: block;
                    }
                .img-responsive.defaut {
                    display: none;
                }
            }
        }
    }
}

/*******************************************************************************
* RÉALISATIONS
*******************************************************************************/

#section-realisations {
    padding: 75px 0;
    background: #000;
    .text {
        p {
            font-family: $font-family-2;
            font-size: $font-size-28;
            font-weight: normal;
            color: #d6d6d6;
            margin: 0;
            line-height: 1.3;
        }
    }
    .realisations.zoom-gallery {
        padding-top: 50px;
        display: flex;
        flex-wrap: wrap;
        a.item {
            width: 50%;
            padding: 25px;
            .bloc {
                .titre {
                    h4 {
                        font-size: $font-size-32;
                        font-weight: normal;
                        color: #fff;
                        padding-left: 10px;
                    }
                }
                .image {
                    padding: 5px 0;
                    position: relative;
                    .img-fleche {
                        position: absolute;
                        bottom: 15px;
                        right: 12%;
                        opacity: 0;
                        transition: 0.5s;
                    }
                }
                .description {
                    width: 85%;
                    p {
                        font-family: $font-family-2;
                        font-size: $font-size-28;
                        font-weight: normal;
                        color: #fff;
                        margin: 0;
                        line-height: 1.3;
                        padding-left: 10px;
                    }
                }
                .overlay {
                    background-color: rgba(0, 0, 0, 0.6);
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    opacity: 0;
                    transition: all 0.3s ease-out;
                }
            }
            &:hover {
                .image {
                    .img-responsive {
                        border: 3px solid #d6d6d6;
                    }
                    .overlay {
                        opacity: 1;
                    }
                    .img-fleche {
                        opacity: 1;
                        transition: 0.5s;
                        .img-responsive {
                            border: none;
                        }
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* EMPLOIS
*******************************************************************************/

.section-gallerie {
    padding: 75px 0;
    background: #000;
    .text {
        p {
            font-family: $font-family-2;
            font-size: $font-size-28;
            font-weight: normal;
            color: #d6d6d6;
            margin: 0;
            line-height: 1.3;
            strong {
                color: #fff;
                font-weight: bold;
            }
        }
    }
    main {
        padding-top: 75px;
        h3 {
            font-weight: normal;
            font-size: $font-size-48;
            color: #d6d6d6;
            padding-bottom: 50px;
        }
        .container-fluid.row.flex {
            flex-wrap: wrap;
            .item-poste.emploi {
                width: 45%;
                margin-bottom: 75px;
                h4 {
                    font-family: $font-family-2;
                    font-size: $font-size-28;
                    font-weight: 500;
                    color: #fff;
                    text-transform: uppercase;
                    padding-bottom: 15px;
                }
                p, li {
                    font-family: $font-family-2;
                    font-size: $font-size-26;
                    font-weight: normal;
                    color: #d6d6d6;
                    margin: 0;
                    line-height: 1.3;
                }
                ul {
                    padding-left: 45px;
                    padding-top: 10px;
                    padding-bottom: 50px;
                    li {
                        text-transform: initial;
                    }
                }
                button {
                    width: 45% !important; 
                }
                &:nth-child(odd) {
                    border-right: 2px solid #fff;
                }
                &:nth-child(even) {
                    padding-left: 10%;
                }
            }
        }
    }
}

#section-form {
    padding: 50px 0;
    background: #d6d6d6;
    border-bottom: 125px solid #000;
    h3 {
        font-weight: normal;
        font-size: $font-size-48;
        color: $color-black;
    }
    form div.label-wrapper {
        width: 250px;
        label.control-label {
            color: $color-black;
        }
    }
    button.btn.submit-emploi {
        background: #000;
        color: $color-white;
        &:hover {
            color: #d6d6d6;
        }
    }
    label.btn.filedoc {
        background: #b2b2b2;
        color: $color-black;
        text-transform: uppercase;
        font-family: $font-family-1;
    }
}

/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#section-coordonnees {
    padding: 75px 0;
    background: #000;
    .bloc {
        .left-side {
            display: flex;
            width: 50%;
            margin-right: 5%;
            .icon {
                margin-right: 25px;
            }
            .text {
                width: 100%;
                p {
                    font-family: $font-family-2;
                    font-size: $font-size-30;
                    font-weight: 500;
                    color: #fff;
                    margin: 0;
                    line-height: 1.3;
                    span {
                        font-size: $font-size-34;
                    }
                }
            }
        }
        .right-side {
            width: 50%;
            .bloc {
                display: flex;
                align-items: center;
                .icon {
                    margin-right: 25px;
                }
                .text {
                    width: 100%;
                    h4 {
                        font-weight: normal;
                        font-size: $font-size-34;
                        color: $color-white;
                    }
                }
            }
        }
    }
    .bloc.map-form {
        padding-top: 50px;
        .left-side {
            display: block;
        }
    }
}



/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: 1415px) {
   
.slideshow-wrap .caption {
    top: 70%;
    left: 25.5%;
}
}

@media screen and (max-width: $size-md-max) {
   


}

@media screen and (max-width: 1210px) {
   
nav.module-menu .menu-navbar .wrapper.right .section-tel, nav.module-menu .menu-navbar .wrapper.right .main-menu > ul, nav.module-menu .menu-navbar .wrapper.right .section-rbq {
    display: none;
}
nav.module-menu .menu-toggle {
    position: absolute;
    right: 0;
}
#section-form label.btn.filedoc {
    margin-top: 10px;
}
}

@media screen and (max-width: 1024px) {
    
nav.module-menu .menu-navbar .wrapper.left .logo {
    max-width: 155px;
    margin-left: 15px;
}
nav.module-menu .menu-navbar {
    height: 100px;
}
nav.module-menu .menu-navbar .wrapper.right .section-rbq {
    display: none;
}
.slideshow-wrap .caption {
    top: 60%;
    left: 28%;
}
#menu-mobile .section-rbq div {
    color: #d6d6d6;
}
#main-section .section-text-img {
    flex-direction: column;
}
#main-section .section-text-img .left-side {
    width: 100%;
    margin-right: 0;
    padding-bottom: 25px;
}
#main-section .section-text-img .right-side {
    width: 100%;
    justify-content: center;
}
#main-section .section-burst {
    justify-content: center;
}
#main-section .section-burst a.burst {
    width: 60%;
}
#section-text-burst .container.flex .right-side a.burst .text h3, #section-text-burst.propos .left-side a.burst .text h3, #section-burst a.burst .text h3 {
    font-size: 2.5rem;
}
#section-coordonnees .bloc.map-form .container.flex.items-center {
    flex-direction: column;
}
#section-coordonnees .bloc.map-form .left-side {
    width: 100%;
    margin: 0 0 35px 0;
}
#section-coordonnees .bloc.map-form .right-side {
    width: 70%;
}
.section-gallerie main .container-fluid.row.flex .item-poste.emploi:nth-child(odd) {
    padding-right: 50px;
}
#section-form .buttons.flex-auto {
    margin-left: 65px;
}
}

@media screen and (max-width: $size-sm-max) {
 
nav.module-menu .menu-toggle .word {
    font-size: 18px;
}   
.slideshow-wrap .caption {
    top: 56%;
    left: 33%;
}
#main-section {
    padding: 50px 0;
}
#main-section .section-burst a.burst, #main-section .section-burst .burst.section-logo {
    width: 75%;
}
#section-text-burst .container.flex, #section-coordonnees .bloc .container.flex, .section-gallerie main .container-fluid.row.flex {
    flex-direction: column;
}
#section-text-burst .container.flex .left-side, #section-coordonnees .bloc .left-side {
    width: 100%;
    margin: 0 0 25px 0;
}
#section-text-burst .container.flex .right-side, #section-coordonnees .bloc .right-side{
    width: 100%;
}
#section-text-burst.propos .right-side {
    justify-content: center;
}
#section-burst a.burst .text h4 {
    font-size: 1.5rem;
}
#section-coordonnees .bloc.map-form .right-side {
    width: 80%;
}
#section-form form div.label-wrapper {
    width: 150px;
}
#section-form .form-group.col-xs-12.col-sm-7.col-sm-push-2, #section-form .form-group.col-xs-12.col-sm-8.col-sm-push-2 {
    width: 80%;
    left: 5%;
}
#section-form .buttons.flex-auto {
    margin-left: 30px;
}
.section-gallerie main .container-fluid.row.flex .item-poste.emploi:nth-child(odd) {
    border: none;
}
.section-gallerie main .container-fluid.row.flex .item-poste.emploi {
    width: 100%;
}
.section-gallerie main .container-fluid.row.flex .item-poste.emploi:nth-child(even) {
    padding-left: 0;
}
}

@media screen and (max-width: $size-xs-max) {
 
nav.module-menu .menu-navbar .wrapper.left .logo {
    max-width: 125px;
}
nav.module-menu .menu-navbar, nav.module-menu {
    height: 85px;
}
.slideshow-wrap .caption {
    top: 65%;
    left: 52%;
    width: 100%;
}
#main-section .section-text-img .right-side {
    flex-direction: column;
}
#main-section .section-text-img .right-side .bloc {
    margin: 0 auto 15px auto;
    padding-left: 20%;
}
#main-section .section-text-img .right-side .image {
    margin: auto;
}
#main-section .section-burst a.burst, #main-section .section-burst .burst.section-logo {
    width: 100%;
}
#main-section .section-burst a.burst .icon {
    width: 30%;
}
#main-section .section-burst a.burst .text h3 {
    font-size: 2.3rem;
}
#section-text-burst .container.flex .left-side .bloc .icon, #section-list .container.flex .bloc .icon {
    padding: 8px 30px 0 0;
}
#section-text-burst .container.flex .left-side .bloc .text, #section-list .container.flex .bloc .text {
    width: 100%;
}
#section-text-burst .container.flex .right-side a.burst .text h4 {
    font-size: 1.5rem;
}
#section-text-burst .container.flex .right-side a.burst .icon, #section-burst a.burst .icon, #section-text-burst.propos .left-side a.burst .icon {
    width: 25%;
}
#section-list .container.flex {
    flex-direction: column;
}
#section-list .container.flex .left-side {
    width: 100%;
    margin: 0 0 15px 0;
}
#section-list .container.flex .right-side {
    width: 100%;
}
#section-burst .container.flex, #section-text-burst.propos .right-side {
    flex-direction: column;
}
#section-burst a.burst {
    width: 100%;
}
#section-text-burst.propos .left-side a.burst .text h3 {
    font-size: 2.2rem;
}
#section-text-burst.propos .right-side .images.mr2 {
    margin-bottom: 10px;
}
#section-text {
    padding-bottom: 50px;
}
#section-coordonnees .bloc.map-form .right-side {
    width: 100%;
}
#section-form .form-group.col-xs-12.col-sm-7.col-sm-push-2, #section-form .form-group.col-xs-12.col-sm-8.col-sm-push-2 {
    width: 100%;
    left: 0;
}
#section-form .buttons.flex-auto {
    margin-left: 0;
}
.section-gallerie {
    padding: 75px 0 25px 0;
}
#section-realisations .realisations.zoom-gallery a.item {
    width: 100%;
    padding: 0 0 50px 0;
}
}

@media screen and (max-width: 360px) {

nav.module-menu .menu-toggle .word {
    font-size: 14px;
}
.slideshow-wrap .caption {
    top: 62%;
}
#main-section .section-text-img .right-side .bloc {
    width: 330px;
}
#main-section .section-burst a.burst .text h3 {
    font-size: 2.1rem;
}
#main-section .section-burst a.burst .icon {
    width: 20%;
}
#section-text-burst .container.flex .right-side a.burst .text h3 {
    font-size: 2.2rem;
}
#section-coordonnees .bloc .right-side .bloc .text h4 {
    font-size: 1.5rem;
}
}