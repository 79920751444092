.btn {
    background-color: #d6d6d6;
    color: $color-black;
    border: none;
    border-radius: 0;
    padding: 5px;
    font-weight: normal;
    font-size: $font-size-30;
    width: 100%;
    transition: all 0.2s ease-in;
    
    &:hover {
        transition: all 0.2s ease-in;
        color: $color-white;
    }
}
